import {
  BooleanField,
  Datagrid,
  DeleteWithConfirmButton,
  EditButton,
  FunctionField,
  List,
  ShowButton,
  TextField,
  useRecordContext,
} from "react-admin";

export const Actions = () => {
  const record = useRecordContext();

  return (
    <div>
      <DeleteWithConfirmButton
        title="Delete User"
        confirmContent="Are you sure you want to delete this user?"
        confirmColor="warning"
        confirmTitle={`Delete ${record?.email}`}
        mutationMode="pessimistic"
        mutationOptions={{
          onSuccess: () => {
            window.location.reload();
          },
        }}
      />
      <EditButton />
      <ShowButton />
    </div>
  );
};

export default () => (
  <List>
    <Datagrid bulkActionButtons={false}>
      <TextField source="email" />
      <TextField source="username" />
      <TextField source="status" />
      <TextField source="privyWalletAddress" label="Main Privy Wallet" />
      <FunctionField
        label="Golden Ticket"
        render={(record) =>
          record?.goldenTicketQty > 0 ? `Yes (${record?.goldenTicketQty})` : ""
        }
      />
      <FunctionField
        label="Banned"
        render={(record) => (record?.isBanned ? "Yes" : "")}
      />
      <TextField source="internalNote" />
      <TextField source="userLevel" />
      <TextField source="ipRisk" />
      <TextField source="countryRisk" />
      <Actions />
    </Datagrid>
  </List>
);
