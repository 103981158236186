import { Button } from "react-admin";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import { dataProvider } from "../providers/dataProvider";

type RestoreProps = {
  resource: string;
  id: string;
};

export const Restore = ({ resource, id }: RestoreProps) => {
  return (
    <Button
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        dataProvider.restore(resource, id).then(() => window.location.reload());
      }}
      color="success"
      label="Restore"
      startIcon={<RestoreFromTrashIcon />}
    />
  );
};
