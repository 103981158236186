const _arrayBufferToBase64 = (buffer: ArrayBuffer) => {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

export const createFile = async (url: string | undefined, filename: string) => {
  if (!url) {
    return { base64: null, file: null };
  }
  
  const imageUrlData = await fetch(url, {
    mode: "cors",
    method: "GET",
    cache: "no-cache",
    headers: {
      Origin: window.location.origin,
    },
  });
  const buffer = await imageUrlData.arrayBuffer();
  const stringifiedBuffer = _arrayBufferToBase64(buffer);
  const contentType = imageUrlData.headers.get("content-type");
  const base64 = `data:${contentType};base64,${stringifiedBuffer}`;
  const file = new File([buffer], filename, { type: contentType || "" });
  return { base64, file };
};
