import { ResourceCallbacks } from "react-admin";
import { createFile } from "../../../utils/file";

export const playlistsCallbacks: ResourceCallbacks[] = [
  {
    resource: "playlists",
    beforeCreate: async (params) => {
      const {
        thumbnailImageUrl,
        backgroundColors,
        cardBackgroundImageUrl,
        customWallets,
        filterBy,
        ...rest
      } = params.data;
      const colors = backgroundColors
        ? backgroundColors.map(({ color }: { color: string }) => color)
        : [];

      const { base64: thumbnailImageBase64 } = thumbnailImageUrl?.src
        ? await createFile(thumbnailImageUrl.src, rest.title)
        : { base64: null };

      const { base64: cardBackgroundImageBase64 } = cardBackgroundImageUrl
        ? await createFile(cardBackgroundImageUrl.src, rest.title)
        : { base64: null };

      return {
        ...params,
        data: {
          ...rest,
          filters: {
            customWallets: customWallets || false,
            filterBy: filterBy || "profit",
          },
          thumbnailImage: thumbnailImageBase64,
          cardBackgroundImage: cardBackgroundImageBase64,
          backgroundColors: colors,
        },
      };
    },
    beforeUpdate: async (params) => {
      const {
        thumbnailImageUrl,
        backgroundColors,
        cardBackgroundImageUrl,
        customWallets,
        filterBy,
        ...rest
      } = params.data;
      const colors = backgroundColors
        ? backgroundColors.map(({ color }: { color: string }) => color)
        : [];

      const { base64: thumbnailImageBase64 } = thumbnailImageUrl?.src
        ? await createFile(thumbnailImageUrl.src, rest.title)
        : { base64: null };

      const { base64: cardBackgroundImageBase64 } = cardBackgroundImageUrl?.src
        ? await createFile(cardBackgroundImageUrl.src, rest.title)
        : { base64: null };

      return {
        ...params,
        data: {
          ...rest,
          filters: {
            customWallets: customWallets || false,
            filterBy: filterBy || "profit",
          },
          thumbnailImage: thumbnailImageBase64,
          cardBackgroundImage: cardBackgroundImageBase64,
          backgroundColors: colors,
        },
      };
    },
    afterGetOne: async (params) => {
      const { thumbnailImageUrl, cardBackgroundImageUrl, filters, ...rest } =
        params.data;

      const { file: thumbnailImageFile } = thumbnailImageUrl
        ? await createFile(thumbnailImageUrl, rest.title)
        : { file: null };

      const { file: cardBackgroundImageFile } = cardBackgroundImageUrl
        ? await createFile(cardBackgroundImageUrl, rest.title)
        : { file: null };

      return {
        ...params,
        data: {
          ...rest,
          customWallets: filters?.customWallets,
          filterBy: filters?.filterBy,
          thumbnailImageUrl: {
            title: rest.name,
            rawFile: thumbnailImageFile,
            src: thumbnailImageUrl,
          },
          cardBackgroundImageUrl: {
            title: rest.name,
            rawFile: cardBackgroundImageFile,
            src: cardBackgroundImageUrl,
          },
          backgroundColors: rest.backgroundColors.map((color: string) => ({
            color,
          })),
        },
      };
    },
  },
];
