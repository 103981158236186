import {
  Create,
  ImageField,
  ImageInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";

export default () => (
  <Create redirect="/stickers">
    <SimpleForm>
      <ReferenceInput
        isRequired
        source="categoryId"
        reference="stickers-categories"
        label="Category"
      />
      <TextInput source="name" />
      <SelectInput
        source="type"
        required
        defaultValue={"Standard"}
        choices={[
          { id: "Standard", name: "Standard" },
          { id: "Animated", name: "Animated" },
        ]}
      />
      <TextInput source="groupType" />
      <ImageInput source="url" accept={{ "image/*": [".svg"] }} label="Image">
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Create>
);
