import { ResourceCallbacks } from "react-admin";
import { createFile } from "../../../utils/file";

export const stickersCallbacks: ResourceCallbacks[] = [
  {
    resource: "stickers",
    beforeCreate: async (params) => {
      const { url, ...rest } = params.data;
      const { base64 } = await createFile(url.src, rest.name);

      return {
        ...params,
        data: {
          ...rest,
          stickerImage: base64,
        },
      };
    },
    beforeUpdate: async (params) => {
      const { url, ...rest } = params.data;
      const { base64 } = await createFile(url.src, rest.name);

      return {
        ...params,
        data: {
          ...rest,
          stickerImage: base64,
        },
      };
    },
    afterGetOne: async (params) => {
      const { url, ...rest } = params.data;
      const { file } = await createFile(url, rest.name);
      return {
        ...params,
        data: {
          ...rest,
          url: {
            name: rest.name,
            rawFile: file,
            src: url,
          },
        },
      };
    },
  },
];
