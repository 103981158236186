import {
  ArrayField,
  Datagrid,
  DateField,
  DeleteWithConfirmButton,
  EditButton,
  FunctionField,
  ImageField,
  List,
  ShowButton,
  SingleFieldList,
  TextField,
  useRecordContext,
} from "react-admin";
import { Publish } from "../../components/Publish";
import { Unpublish } from "../../components/Unpublish";

const Actions = () => {
  const record = useRecordContext();

  return (
    <div>
      {record?.published ? (
        <Unpublish resource="playlists" id={record?.id as string} />
      ) : (
        <Publish resource="playlists" id={record?.id as string} />
      )}
      <EditButton />
      {record?.type === "TRADES" && <ShowButton label="Preview" />}
      <DeleteWithConfirmButton
        title="Delete Playlist"
        confirmContent="Are you sure you want to delete this playlist?"
        confirmColor="warning"
        confirmTitle={`Delete ${record?.name}`}
        mutationMode="pessimistic"
        mutationOptions={{
          onSuccess: () => {
            window.location.reload();
          },
        }}
      />
    </div>
  );
};

export default () => (
  <List>
    <Datagrid bulkActionButtons={false}>
      <TextField source="title" />
      <TextField source="subtitle" />
      <TextField source="description" />
      <TextField source="tag" />
      <FunctionField
        label="Status"
        render={(record) => (record?.published ? "Published" : "Draft")}
      />
      <TextField source="type" />
      <ImageField source="thumbnailImageUrl" label="Thumbnail Image" />
      <ImageField
        source="cardBackgroundImageUrl"
        label="Card Background Image"
      />
      <ArrayField source="backgroundColors" label="Background Colors">
        <SingleFieldList>
          <FunctionField
            render={(record) => {
              return (
                <>
                  <div
                    style={{
                      background: record,
                      width: 20,
                      height: 20,
                    }}
                  />
                  <div>{record}</div>
                </>
              );
            }}
          />
        </SingleFieldList>
      </ArrayField>
      <Actions />
    </Datagrid>
  </List>
);
