import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import { DynamicContextProvider } from "@dynamic-labs/sdk-react-core";
import { QueryClient, QueryClientProvider } from "react-query";
import { Helmet } from "react-helmet";
import * as Sentry from "@sentry/react";

const client = new QueryClient();

Sentry.init({
  dsn: "https://3f5982de6609afbfaec3e2327dd41284@o4507696602742784.ingest.us.sentry.io/4508098411036672",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Helmet>
      <title>
        {import.meta.env.VITE_NODE_ENV === "production"
          ? "Tokie CMS"
          : "Tokie Staging CMS"}
      </title>
    </Helmet>
    <DynamicContextProvider
      settings={{
        environmentId: import.meta.env.VITE_DYNAMIC_ENV_ID,
      }}
    >
      <QueryClientProvider client={client}>
        <App />
      </QueryClientProvider>
    </DynamicContextProvider>
  </React.StrictMode>
);
