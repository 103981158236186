import { AuthActionType } from "react-admin";
import { getAccessToken } from "./utils";
import { UseDynamicContext } from "@dynamic-labs/sdk-react-core/src/lib/context/DynamicContext/useDynamicContext";
import { UserProfile } from "@dynamic-labs/sdk-react-core";

const authProvider =
  (
    dynamicContext: UseDynamicContext,
    refresh: () => Promise<UserProfile | undefined>
  ) =>
  async (type: AuthActionType, params: any) => {
    if (type === "AUTH_LOGIN") {
      return Promise.resolve();
    }
    if (type === "AUTH_LOGOUT") {
      await dynamicContext.handleLogOut();
      return Promise.resolve();
    }
    if (type === "AUTH_ERROR") {
      const data = await refresh();

      if (data) {
        history.back();
      } else {
        // await dynamicContext.handleLogOut();
      }

      return Promise.resolve();
    }

    if (type === "AUTH_CHECK") {
      const accessToken = getAccessToken();

      const response = await fetch(`${import.meta.env.VITE_API_URL}/auth/me`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();
      if (data.message === "UNAUTHORIZED") {
        return Promise.reject("Unauthorized");
      }
      return Promise.resolve();
    }

    if (type === "AUTH_GET_PERMISSIONS") {
      return Promise.resolve();
    }

    return Promise.reject("Unknown method");
  };

export default authProvider;
