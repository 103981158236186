import { DataProvider, withLifecycleCallbacks } from "react-admin";
import { filtersCallbacks } from "./callbacks/filters.callbacks";
import { stickersCallbacks } from "./callbacks/stickers.callbacks";
import { profilesCallbacks } from "./callbacks/profiles.callbacks";
import { getAccessToken } from "../authProvider/utils";
import { clipsCallbacks } from "./callbacks/clips.callbacks";
import { playlistsCallbacks } from "./callbacks/playlists.callbacks";

const dataProviderHandler: DataProvider = {
  getList: async (resource, params) => {
    const offset = params.pagination?.page
      ? (params.pagination.page - 1) * params.pagination.perPage
      : 0;
    const limit = params.pagination?.perPage ? params.pagination.perPage : 10;
    const accessToken = getAccessToken();
    const additionalParams = new URLSearchParams();
    if (params.filter) {
      Object.keys(params.filter).forEach((key) => {
        additionalParams.append(key, params.filter[key]);
      });
    }
    const response = await fetch(
      `${
        import.meta.env.VITE_API_URL
      }/${resource}?offset=${offset}&limit=${limit}${
        additionalParams ? `&${additionalParams.toString()}` : ""
      }`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data = await response.json();
    return { data: data.items, total: data.total };
  },
  getOne: async (resource, params) => {
    const accessToken = getAccessToken();
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/${resource}/${params.id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data = await response.json();
    return { data: data };
  },
  create: async (resource, params) => {
    const accessToken = getAccessToken();
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/${resource}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(params.data),
      }
    );

    const data = await response.json();

    if (response.status !== 200) {
      throw new Error(data.message);
    }

    return { data: data };
  },
  delete: async (resource, params) => {
    const accessToken = getAccessToken();
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/${resource}/${params.id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data = await response.json();
    return { data: data };
  },
  deleteMany: async (resource, params) => {
    const accessToken = getAccessToken();
    const promises = params.ids.map(async (id) => {
      const response = await fetch(
        `${import.meta.env.VITE_API_URL}/${resource}/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return await response.json();
    });
    return { data: await Promise.all(promises) };
  },
  restore: async (resource: string, id: string) => {
    const accessToken = getAccessToken();
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/${resource}/${id}/restore`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data = await response.json();
    return { data: data };
  },
  update: async (resource, params) => {
    const accessToken = getAccessToken();
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/${resource}/${params.id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(params.data),
      }
    );
    const data = await response.json();
    return { data: data };
  },
  getMany: async (resource, params) => {
    const accessToken = getAccessToken();
    const promises = params.ids.map(async (id) => {
      const response = await fetch(
        `${import.meta.env.VITE_API_URL}/${resource}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return await response.json();
    });
    return { data: await Promise.all(promises) };
  },
  publish: async (resource: string, id: string) => {
    const accessToken = getAccessToken();
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/${resource}/${id}/publish`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data = await response.json();
    return { data: data };
  },
  unpublish: async (resource: string, id: string) => {
    const accessToken = getAccessToken();
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/${resource}/${id}/unpublish`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data = await response.json();
    return { data: data };
  },

  // TODO: Implement the following methods

  getManyReference: async (resource, params) => {
    const response = await fetch(`http://localhost:3000/${resource}`);
    const data = await response.json();
    return { data: data };
  },

  updateMany: async (resource, params) => {
    const response = await fetch(`http://localhost:3000/${resource}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    });
    const data = await response.json();
    return { data: data };
  },
};

export const dataProvider = withLifecycleCallbacks(dataProviderHandler, [
  ...filtersCallbacks,
  ...stickersCallbacks,
  ...profilesCallbacks,
  ...clipsCallbacks,
  ...playlistsCallbacks,
]);
