import { FieldProps, useRecordContext } from "react-admin";

export const VideoField = (props: FieldProps) => {
  const record = useRecordContext(props);
  return record?.[props.source] ? (
    <video
      src={`${record[props.source]}`}
      controls
      width="320"
      height="240"
    ></video>
  ) : (
    <>-</>
  );
};
