import { useState } from "react";
import {
  ArrayInput,
  BooleanInput,
  Create,
  DateInput,
  ImageField,
  ImageInput,
  NumberInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from "react-admin";

import { ColorInput } from "react-admin-color-picker";

export default () => {
  const [selectedType, setSelectedType] = useState("COINS");

  const handleTypeChange = (event: any) => {
    setSelectedType(event.target.value);
  };
  return (
    <Create redirect="/playlists">
      <SimpleForm>
        <TextInput required source="title" />
        <TextInput source="subtitle" />
        <TextInput source="description" />
        <TextInput source="tag" />
        <NumberInput source="order" defaultValue={1} />

        <SelectInput
          source="type"
          required
          defaultValue={"COINS"}
          onChange={handleTypeChange}
          choices={[
            { id: "COINS", name: "COINS" },
            { id: "TRADES", name: "TRADES" },
            { id: "WALLETS", name: "WALLETS" },
          ]}
        />

        {selectedType === "WALLETS" && <BooleanInput source="customWallets" />}
        {selectedType === "TRADES" && (
          <SelectInput
            source="filterBy"
            required
            label="Filter By"
            defaultValue={"profit"}
            choices={[
              { id: "profit", name: "profit" },
              { id: "roi", name: "roi" },
              { id: "realized_pnl", name: "realized_pnl" },
              { id: "unrealized_pnl", name: "unrealized_pnl" },
              { id: "total_sells", name: "total_sells" },
              { id: "total_buys", name: "total_buys" },
              { id: "total_buys_in_dollars", name: "total_buys_in_dollars" },
              { id: "total_sells_in_dollars", name: "total_sells_in_dollars" },
              { id: "biggest_winners", name: "biggest_winners" },
              { id: "biggest_losers", name: "biggest_losers" },
              { id: "roi_winners", name: "roi_winners" },
              { id: "roi_losers", name: "roi_losers" },
            ]}
          />
        )}

        <DateInput source="dateFrom" />
        <DateInput source="dateTo" />

        <ArrayInput source="backgroundColors" label="Colors">
          <SimpleFormIterator inline>
            <ColorInput source="color" label="Color" />
          </SimpleFormIterator>
        </ArrayInput>

        <ImageInput
          source="cardBackgroundImageUrl"
          accept={{ "image/*": [".svg"] }}
          label="Card Background Image"
        >
          <ImageField source="src" title="title" />
        </ImageInput>

        <ImageInput
          source="thumbnailImageUrl"
          accept={{ "image/*": [".svg"] }}
          label="Thumbnail Image"
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};
