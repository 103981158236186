import {
  ImageField,
  ImageInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
} from "react-admin";
import { dataProvider } from "../../providers/dataProvider";
import * as Sentry from "@sentry/react";

export default () => {
  const notify = useNotify();
  const redirect = useRedirect();

  const onSubmit = async (data: any) => {
    try {
      const promises = data.images.map((image: any) => {
        return dataProvider.create("stickers", {
          data: {
            type: data.type,
            categoryId: data.categoryId,
            url: image,
            name: null,
            groupType: data.groupType,
          },
        });
      });

      await Promise.all(promises);
      notify("Stickers created successfully", { type: "success" });
      redirect("/stickers");
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      notify(`Error: Sticker creation failed - ${error}`, { type: "error" });
    }
  };

  return (
    <div>
      <SimpleForm onSubmit={onSubmit}>
        <ReferenceInput source="categoryId" reference="stickers-categories" />
        <SelectInput
          source="type"
          required
          defaultValue={"Standard"}
          choices={[
            { id: "Standard", name: "Standard" },
            { id: "Animated", name: "Animated" },
          ]}
        />
        <TextInput source="groupType" />
        <ImageInput
          source="images"
          accept={{ "image/*": [".svg"] }}
          label="Image"
          multiple
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </div>
  );
};
