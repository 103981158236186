import { Button } from "react-admin";
import { dataProvider } from "../providers/dataProvider";

type PublishProps = {
  resource: string;
  id: string;
};

export const Publish = ({ resource, id }: PublishProps) => {
  return (
    <Button
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        dataProvider.publish(resource, id).then(() => window.location.reload());
      }}
      color="success"
      label="Publish"
      //   startIcon={<RestoreFromTrashIcon />}
    />
  );
};
