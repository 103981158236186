import { useEffect, useState } from "react";
import {
  ArrayInput,
  BooleanInput,
  DateInput,
  Edit,
  ImageField,
  ImageInput,
  NumberInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  useRecordContext,
} from "react-admin";

import { ColorInput } from "react-admin-color-picker";

const CustomFilters = ({
  selectedType,
  setSelectedType,
}: {
  selectedType: string;
  setSelectedType: (selectedType: string) => void;
}) => {
  const record = useRecordContext();

  useEffect(() => {
    setSelectedType(record?.type);
  }, []);

  return (
    <>
      {selectedType === "WALLETS" && <BooleanInput source="customWallets" />}
      {selectedType === "TRADES" && (
        <SelectInput
          source="filterBy"
          required
          label="Filter By"
          defaultValue={"profit"}
          choices={[
            { id: "profit", name: "profit" },
            { id: "roi", name: "roi" },
            { id: "realized_pnl", name: "realized_pnl" },
            { id: "unrealized_pnl", name: "unrealized_pnl" },
            { id: "total_sells", name: "total_sells" },
            { id: "total_buys", name: "total_buys" },
            { id: "total_buys_in_dollars", name: "total_buys_in_dollars" },
            { id: "total_sells_in_dollars", name: "total_sells_in_dollars" },
            { id: "biggest_winners", name: "biggest_winners" },
            { id: "biggest_losers", name: "biggest_losers" },
            { id: "roi_winners", name: "roi_winners" },
            { id: "roi_losers", name: "roi_losers" },
          ]}
        />
      )}
    </>
  );
};

export default () => {
  const [selectedType, setSelectedType] = useState("");

  return (
    <Edit
      title={"Edit playlist"}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => window.location.replace("/playlists"),
      }}
    >
      <SimpleForm>
        <TextInput required source="title" />
        <TextInput source="subtitle" />
        <TextInput source="description" />
        <TextInput source="tag" />
        <NumberInput source="order" />

        <SelectInput
          source="type"
          required
          onChange={(event) => setSelectedType(event.target.value)}
          choices={[
            { id: "COINS", name: "COINS" },
            { id: "TRADES", name: "TRADES" },
            { id: "WALLETS", name: "WALLETS" },
          ]}
        />
        <CustomFilters
          selectedType={selectedType}
          setSelectedType={setSelectedType}
        />

        <DateInput source="dateFrom" />
        <DateInput source="dateTo" />

        <ArrayInput source="backgroundColors" label="Colors">
          <SimpleFormIterator inline>
            <ColorInput source="color" label="Color" />
          </SimpleFormIterator>
        </ArrayInput>

        <ImageInput
          source="cardBackgroundImageUrl"
          accept={{ "image/*": [".svg"] }}
          label="Card Background Image"
        >
          <ImageField source="src" title="title" />
        </ImageInput>

        <ImageInput
          source="thumbnailImageUrl"
          accept={{ "image/*": [".svg"] }}
          label="Thumbnail Image"
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
};
