import {
  BooleanInput,
  Edit,
  ImageField,
  ImageInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { ColorInput } from "react-admin-color-picker";

export default () => (
  <Edit
    title={"Edit profile"}
    mutationMode="pessimistic"
    mutationOptions={{ onSuccess: () => window.location.replace("/profiles") }}
  >
    <SimpleForm>
      <TextInput source="id" disabled />
      <TextInput source="email" disabled />
      <TextInput source="username" />
      <ImageInput
        source="avatarImage"
        accept={{ "image/*": [".svg"] }}
        label="Image"
        id="avatarImage"
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <ColorInput source="avatarColor" />
      <TextInput source="status" disabled />
      <BooleanInput source="isBanned" />
      <TextInput source="internalNote" />
      <TextInput source="userLevel" disabled />
      <TextInput source="ipRisk" disabled />
      <TextInput source="countryRisk" disabled />
    </SimpleForm>
  </Edit>
);
