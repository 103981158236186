import { Datagrid, List, ListProps, TextField } from "react-admin";

type WalletListProps = Omit<ListProps, "children">;

export default (props: WalletListProps) => (
  <List {...props}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="address" />
      <TextField source="createdAt" />
    </Datagrid>
  </List>
);
