import {
  Button,
  Datagrid,
  DeleteWithConfirmButton,
  EditButton,
  Empty,
  ImageField,
  Link,
  List,
  ListActions,
  ReferenceField,
  TextField,
  useRecordContext,
} from "react-admin";
import { Restore } from "../../components/Restore";
import AddIcon from "@mui/icons-material/Add";
import { ROUTES } from "../../utils/routes";

export const Actions = () => {
  const record = useRecordContext();

  return (
    <div>
      <EditButton />
      {record?.deletedAt ? (
        <Restore resource="stickers" id={record.id.toString()} />
      ) : (
        <DeleteWithConfirmButton
          title="Delete Sticker"
          confirmContent="Are you sure you want to delete this sticker?"
          confirmColor="warning"
          confirmTitle={`Delete ${record?.name}`}
          mutationMode="pessimistic"
          mutationOptions={{
            onSuccess: () => {
              window.location.reload();
            },
          }}
        />
      )}
    </div>
  );
};

const ToolbarActions = () => (
  <div
    style={{
      display: "flex",
      gap: "0.25rem",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Button
      style={{ marginTop: "0.25rem" }}
      variant="text"
      color="primary"
      component={Link}
      to={{
        pathname: ROUTES.STICKERS_CREATE_MANY,
      }}
      label="Create many"
      startIcon={<AddIcon />}
    />
    <ListActions />
  </div>
);

export const EmptyState = () => (
  <>
    <Empty></Empty>
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "0.5rem",
      }}
    >
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to={{
          pathname: ROUTES.STICKERS_CREATE_MANY,
        }}
        label="Create many"
        startIcon={<AddIcon />}
      />
    </div>
  </>
);

export default () => (
  <List actions={<ToolbarActions />} empty={<EmptyState />}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="name" />
      <ImageField source="url" label="Image" />
      <TextField source="type" />
      <TextField source="groupType" />
      <ReferenceField source="categoryId" reference="stickers-categories" />
      <Actions />
    </Datagrid>
  </List>
);
