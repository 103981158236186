import {
  ArrayField,
  Datagrid,
  DeleteWithConfirmButton,
  EditButton,
  FunctionField,
  ImageField,
  List,
  SingleFieldList,
  TextField,
  useRecordContext,
} from "react-admin";

import { ColorField } from "react-admin-color-picker";
import { Restore } from "../../components/Restore";
import { VideoField } from "../../components/VideoField";

const Actions = () => {
  const record = useRecordContext();

  return (
    <div>
      <EditButton />
      {record?.deletedAt ? (
        <Restore resource="filters" id={record.id.toString()} />
      ) : (
        <DeleteWithConfirmButton
          title="Delete Filter"
          confirmContent="Are you sure you want to delete this filter?"
          confirmColor="warning"
          confirmTitle={`Delete ${record?.name}`}
          mutationMode="pessimistic"
          mutationOptions={{
            onSuccess: () => {
              window.location.reload();
            },
          }}
        />
      )}
    </div>
  );
};

export default () => (
  <List>
    <Datagrid bulkActionButtons={false}>
      <TextField source="name" />
      <ImageField source="iconUrl" label="Icon Image" />
      <ImageField source="priceCursorUrl" label="Price Cursor Image" />
      <TextField source="kind" />
      <TextField source="chartKind" />
      <ColorField source="green" label="Positive candle color" />
      <ColorField source="red" label="Negative candle color" />
      <ArrayField source="gradient" label="Color">
        <SingleFieldList>
          <FunctionField
            render={(record) => {
              return (
                <>
                  <div
                    style={{
                      background: record,
                      width: 20,
                      height: 20,
                    }}
                  />
                  <div>{record}</div>
                </>
              );
            }}
          />
        </SingleFieldList>
      </ArrayField>
      <VideoField source="videoUrl" label="Video" />
      <Actions />
    </Datagrid>
  </List>
);
