import {
  Edit,
  ImageField,
  ImageInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
} from "react-admin";

export default () => (
  <Edit mutationMode="pessimistic" redirect="/stickers">
    <SimpleForm>
      <TextInput source="name" />
      <ImageInput source="url" accept={{ "image/*": [".svg"] }} label="Image">
        <ImageField source="src" title="title" />
      </ImageInput>
      <TextInput source="groupType" />
      <ReferenceInput source="categoryId" reference="stickers-categories" />
    </SimpleForm>
  </Edit>
);
