import { Datagrid, EditButton, List, TextField } from "react-admin";

export default () => (
  <List title={"Sticker categories"}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="name" />
      <TextField source="id" />
      <EditButton />
    </Datagrid>
  </List>
);
