import {
  Edit,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";

export default () => (
  <Edit mutationMode="pessimistic" redirect="/invite-codes">
    <SimpleForm>
      <ReferenceInput
        isRequired
        source="ownerId"
        reference="profiles"
        label="Owner"
      >
        <SelectInput optionText="username" />
      </ReferenceInput>
      <TextInput source="code" />
      <NumberInput source="uses" />
    </SimpleForm>
  </Edit>
);
