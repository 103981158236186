import { ResourceCallbacks } from "react-admin";
import { createFile } from "../../../utils/file";

const beforeCallback = async (params: any) => {
  const { data } = params;

  const gradient =
    data?.gradients?.map(({ color }: { color: string }) => color) ?? [];

  const { base64: iconBase64 } = await createFile(data?.iconUrl?.src, data?.title);
  const { base64: priceCursorBase64 } = await createFile(
    data?.priceCursorIconUrl?.src,
    data?.priceCursorKey
  );

  const { base64: videoBase64 } = await createFile(
    data?.videoUrl?.src,
    data?.videoKey
  );

  return {
    ...params,
    data: {
      ...data,
      iconImage: iconBase64,
      priceCursorImage: priceCursorBase64,
      video: videoBase64,
      gradient,
    },
  };
};

export const filtersCallbacks: ResourceCallbacks[] = [
  {
    resource: "filters",
    beforeCreate: beforeCallback,
    beforeUpdate: beforeCallback,
    afterGetOne: async (params) => {
      const { iconUrl, priceCursorUrl, videoUrl, ...rest } = params.data;

      const { file: iconFile } = await createFile(iconUrl, iconUrl.title);
      const { file: priceCursorFile } = await createFile(
        priceCursorUrl,
        priceCursorUrl?.title
      );
      const { file: videoFile } = await createFile(videoUrl, videoUrl?.title);

      return {
        ...params,
        data: {
          ...rest,
          iconUrl: {
            title: rest.name,
            rawFile: iconFile,
            src: iconUrl,
          },
          priceCursorIconUrl: {
            title: rest.priceCursorKey,
            rawFile: priceCursorFile,
            src: priceCursorUrl,
          },
          videoUrl: {
            title: rest.videoKey,
            rawFile: videoFile,
            src: videoUrl,
          },
          gradients: rest.gradient.map((color: string) => ({ color })),
        },
      };
    },
  },
];
