import { Admin, CustomRoutes, Resource } from "react-admin";
import { BrowserRouter } from "react-router-dom";
import { Route } from "react-router-dom";
import { dataProvider } from "./providers/dataProvider";
import BarChartIcon from "@mui/icons-material/BarChart";
import { CreateFilter, FiltersList, EditFilter } from "./resources/filters";
import {
  CreateStickerCategory,
  StickerCategoriesList,
  EditStickerCategory,
} from "./resources/stickersCategory";
import {
  StickersList,
  CreateSticker,
  EditSticker,
  CreateManyStickers,
} from "./resources/stickers";
import { EditProfile, ProfileShow, ProfilesList } from "./resources/profiles";
import {
  CountryRisksList,
  CreateCountryRisk,
  EditCountryRisk,
} from "./resources/countryRisks";
import { ClipsList } from "./resources/clips";
import CategoryIcon from "@mui/icons-material/Category";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import PeopleIcon from "@mui/icons-material/People";
import authProvider from "./providers/authProvider";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";
import FlagIcon from "@mui/icons-material/Flag";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import { Login } from "./providers/authProvider/Login";
import {
  useDynamicContext,
  useRefreshUser,
} from "@dynamic-labs/sdk-react-core";
import { ROUTES } from "./utils/routes";
import {
  CreatePlaylist,
  EditPlaylist,
  PlaylistsList,
  ShowPlaylist,
} from "./resources/playlists";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import {
  CreateInviteCode,
  EditInviteCode,
  InviteCodesList,
} from "./resources/inviteCodes";

export const App = () => {
  const dynamicContext = useDynamicContext();
  const refresh = useRefreshUser();
  return (
    <BrowserRouter>
      <Admin
        dataProvider={dataProvider}
        loginPage={<Login />}
        authProvider={authProvider(dynamicContext, refresh)}
        darkTheme={null}
      >
        <Resource
          name="profiles"
          list={ProfilesList}
          edit={EditProfile}
          show={ProfileShow}
          icon={PeopleIcon}
        />
        <Resource
          name="filters"
          list={FiltersList}
          create={CreateFilter}
          icon={BarChartIcon}
          edit={EditFilter}
        />
        <Resource
          icon={CategoryIcon}
          options={{ label: "Sticker categories" }}
          name="stickers-categories"
          list={StickerCategoriesList}
          create={CreateStickerCategory}
          edit={EditStickerCategory}
        />
        <Resource
          name="stickers"
          list={StickersList}
          create={CreateSticker}
          edit={EditSticker}
          icon={EmojiEmotionsIcon}
        />
        <Resource name="clips" icon={VideoCameraBackIcon} list={ClipsList} />
        <Resource
          name="country-risks"
          icon={FlagIcon}
          options={{ label: "Country risks" }}
          list={CountryRisksList}
          edit={EditCountryRisk}
          create={CreateCountryRisk}
        />
        <Resource
          icon={SubscriptionsIcon}
          name="playlists"
          create={CreatePlaylist}
          list={PlaylistsList}
          show={ShowPlaylist}
          edit={EditPlaylist}
        />
        <Resource
          name="invite-codes"
          icon={InsertInvitationIcon}
          options={{ label: "Invite codes" }}
          list={InviteCodesList}
          create={CreateInviteCode}
          edit={EditInviteCode}
        />
        <CustomRoutes>
          <Route
            path={ROUTES.STICKERS_CREATE_MANY}
            element={<CreateManyStickers />}
          />
        </CustomRoutes>
      </Admin>
    </BrowserRouter>
  );
};
