import {
  BooleanInput,
  Create,
  NumberInput,
  SimpleForm,
  TextInput,
} from "react-admin";

export default () => (
  <Create redirect="/country-risks">
    <SimpleForm>
      <TextInput source="countryName" />
      <TextInput source="countryCode" />
      <BooleanInput source="isBanned" label="Banned" />
      <NumberInput source="risk" />
    </SimpleForm>
  </Create>
);
