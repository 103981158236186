import { Button } from "react-admin";
import { dataProvider } from "../providers/dataProvider";

type UnpublishProps = {
  resource: string;
  id: string;
};

export const Unpublish = ({ resource, id }: UnpublishProps) => {
  return (
    <Button
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        dataProvider
          .unpublish(resource, id)
          .then(() => window.location.reload());
      }}
      color="success"
      label="Unpublish"
      //   startIcon={<RestoreFromTrashIcon />}
    />
  );
};
