import {
  Datagrid,
  DeleteWithConfirmButton,
  EditButton,
  List,
  ReferenceField,
  TextField,
  useRecordContext,
} from "react-admin";

export const Action = () => {
  const record = useRecordContext();

  return (
    <>
      <EditButton />
      <DeleteWithConfirmButton
        title="Delete Invite Code"
        confirmContent="Are you sure you want to delete this invite code?"
        confirmColor="warning"
        confirmTitle={`Delete ${record?.code}`}
        mutationMode="pessimistic"
        mutationOptions={{
          onSuccess: () => {
            window.location.reload();
          },
        }}
      />
    </>
  );
};

export default () => (
  <List title={"Invite codes"}>
    <Datagrid bulkActionButtons={false}>
      <ReferenceField source="ownerId" reference="profiles" label="Owner">
        <TextField source="username" />
      </ReferenceField>
      <TextField source="code" />
      <TextField source="uses" />
      <Action />
    </Datagrid>
  </List>
);
