import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  ListProps,
  TextField,
} from "react-admin";
import ReactCountryFlag from "react-country-flag";

type ProfileActivityListProps = Omit<ListProps, "children">;

export default (props: ProfileActivityListProps) => (
  <List {...props}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="ip" />
      <FunctionField
        label="Country code"
        render={(record) => (
          <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
            {record.locationCode !== "NONE" && (
              <ReactCountryFlag
                style={{
                  fontSize: "2rem",
                  lineHeight: "2rem",
                }}
                countryCode={record.locationCode}
              />
            )}
            <div> {record.locationCode}</div>
          </div>
        )}
      />
      <DateField source="createdAt" />
    </Datagrid>
  </List>
);
