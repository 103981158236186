import {
  DynamicEmbeddedWidget,
  useDynamicEvents,
} from "@dynamic-labs/sdk-react-core";

export const Login = () => {
  useDynamicEvents("emailVerificationResult", async (data) => {
    window.location.replace("/profiles");
  });

  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ width: "400px", height: "300px" }}>
        <DynamicEmbeddedWidget background="default" />
      </div>
    </div>
  );
};
