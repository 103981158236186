import {
  Button,
  Datagrid,
  DateField,
  DeleteButton,
  DeleteWithConfirmButton,
  FunctionField,
  List,
  ListProps,
  NumberField,
  NumberInput,
  ReferenceField,
  TextField,
  TextInput,
  useRecordContext,
} from "react-admin";
import { VideoField } from "../../components/VideoField";

type ClipListProps = Omit<ListProps, "children">;

const postFilters = [
  <TextInput label="Coin" source="coin" alwaysOn />,
  <TextInput label="Text" source="text" />,
  <NumberInput label="PnL Min" source="pnlMin" />,
  <NumberInput label="PnL Max" source="pnlMax" />,
  <NumberInput label="PnL (%) Min" source="pnlPercentageMin" />,
  <NumberInput label="PnL (%) Max" source="pnlPercentageMax" />,
  <NumberInput label="Clip Length Min" source="clipLengthMin" />,
  <NumberInput label="Clip Length Max" source="clipLengthMax" />,
  <TextInput label="Username" source="username" />,
];

const Actions = () => {
  const record = useRecordContext();
  return (
    <>
      <Button
        onClick={() => window.open(import.meta.env.VITE_REMOTION_STUDIO_URL)}
      >
        <>Show Clip in studio</>
      </Button>
      <DeleteWithConfirmButton
        title="Delete Clip"
        confirmContent="Are you sure you want to delete this clip?"
        confirmColor="warning"
        confirmTitle={`Delete ${record?.s3Key?.split("/")?.[1]} clip`}
        mutationMode="pessimistic"
        mutationOptions={{
          onSuccess: () => {
            window.location.reload();
          },
        }}
      />
    </>
  );
};

export default (props: ClipListProps) => (
  <List {...props} filters={postFilters}>
    <Datagrid bulkActionButtons={false}>
      <DateField source="createdAt" />
      <TextField source="coin" />
      <ReferenceField source="ownerId" reference="profiles" label="Creator">
        <TextField source="username" />
      </ReferenceField>
      <FunctionField
        label="Blob key"
        render={(record) => record.s3Key.split("/")[1]}
      />
      <VideoField source="mp4Url" label="Video" />
      <NumberField source="pnl" />
      <NumberField source="pnlPercentage" />
      <NumberField source="buys" />
      <NumberField source="sells" />
      <NumberField source="clipLength" />
      <FunctionField
        label="Text"
        render={(record) => record?.texts?.join(", ")}
      />
      <Actions />
    </Datagrid>
  </List>
);
