import { ResourceCallbacks } from "react-admin";
import { createFile } from "../../../utils/file";

export const profilesCallbacks: ResourceCallbacks[] = [
  {
    resource: "profiles",
    beforeCreate: async (params) => {
      const { avatarImage, ...rest } = params.data;
      const { base64 } = avatarImage?.src
        ? await createFile(avatarImage.src, rest.name)
        : { base64: null };

      return {
        ...params,
        data: {
          ...rest,
          avatarImage: base64,
        },
      };
    },
    beforeUpdate: async (params) => {
      const { avatarImage, ...rest } = params.data;
      const { base64 } = avatarImage?.src
        ? await createFile(avatarImage.src, rest.name)
        : { base64: null };

      return {
        ...params,
        data: {
          ...rest,
          avatarImage: base64,
        },
      };
    },
    afterGetOne: async (params) => {
      const { avatarUrl, ...rest } = params.data;
      const { file } = await createFile(avatarUrl, rest.name);
      return {
        ...params,
        data: {
          ...rest,
          avatarImage: {
            name: rest.name,
            rawFile: file,
            src: avatarUrl,
          },
        },
      };
    },
  },
];
