import {
  Datagrid,
  List,
  Show,
  SimpleShowLayout,
  TextField,
  useShowController,
} from "react-admin";

export default () => {
  const { record } = useShowController();
  console.log(record);

  return (
    <Show title={`${record?.title} list preview`}>
      <SimpleShowLayout>
        <h3>Playlist preview</h3>
        <List
          resource={`playlists-data/${record?.id}`}
          title={<></>}
          perPage={100}
          filter={{ id: record?.id, date: new Date(), type: record?.filterBy }}
        >
          <Datagrid bulkActionButtons={false}>
            <TextField source="wallet" />
            <TextField source="tokenId" />
            <TextField source="realizedPnl" />
            <TextField source="unrealizedPnl" />
            <TextField source="buysAmount" />
            <TextField source="sellsAmount" />
            <TextField source="buysInDollars" />
            <TextField source="sellsInDollars" />
          </Datagrid>
        </List>
      </SimpleShowLayout>
    </Show>
  );
};
